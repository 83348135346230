import React,{Component} from 'react';
import Header2 from '../Header2';
import axios from 'axios';
import './Menu.css';
import { Link } from "react-router-dom";
import moment from 'moment';





const contacturl ="https://deglennow.onrender.com/contactus"

class ContactMsg extends Component{

    constructor(props){
        super(props)
        console.log (">>>Inside CONCOnstructor")
        this.state={
            contactbook:''

        }
        
    }

    

    renderVbooking = (data) => {
        if (data){
            return data.map ((item) => {
                var start = moment(item.startDate).format('MMM DD YYYY');
                var end = moment(item.endDate).format('MMM DD YYYY');
                return (
                    <>
                        <tr key= {item._id}>
                            <td className="table-light table-striped adjust">{item.date}</td>
                            <td className="table-light table-striped adjust">{item.name}</td>
                            <td className="table-light table-striped adjust">{item.email}</td>
                            <td className="table-light table-striped adjust">{item.phone}</td>
                            <td className="table-light table-striped adjust">{item.Address}</td>
                            <td className="table-light table-striped adjust">{item.Subject}</td>
                            <td className="table-light table-striped adjust">{start}</td>
                            <td className="table-light table-striped adjust">{end}</td>
                            <td className="table-light table-striped adjust">{item.pack}</td>
                            <td className="table-light table-striped adjust">{item.Message}</td>
                            
                        </tr>
                    </>
                     

                   
                )
            })
        }
    }

    render(){
        console.log (">>> Inside CONdetails", this.state)
        if(localStorage.getItem('rtk') == null){
            return(
                <>
                    <Header2/>
                    <div className="background10">
                        <div>
                            <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                            
                            
                        </div>
                    
                    </div>
                </>
                
            )
        }
        return(
            <>
                <Header2/>
                <div className="container">
                    <center><br/>
                        <h4>Contact Messages</h4>
                    </center><br/>
                    <table className="table table-hover">
                        <thead className="table-warning">
                            <tr>
                                <th className="adjust">Date</th>
                                <th className="adjust">Name</th>
                                <th className="adjust">Email</th>
                                <th className="adjust">Phone</th>
                                <th className="adjust">Address</th>
                                <th className="adjust">Subject</th>
                                <th className="adjust">Event Start</th>
                                <th className="adjust">Event End</th>
                                <th className="adjust">No. Users</th>
                                <th className="adjust">Message</th>

                            </tr>
                        </thead>
                        <tbody className="table table-hover">
                            {this.renderVbooking(this.state.contactbook)}
                        </tbody>
                    </table>
                    <Link to="/Settings">
                        <center><button className="btn btn-primary space">Close</button></center>
                    </Link>
                </div>

            </>
        )
    }
    async componentDidMount(){
        console.log (">>> Inside CONdidMount")
        const response = await axios.get(`${contacturl}`)
        this.setState({contactbook:response.data})
      
      
    }
}
export default ContactMsg;