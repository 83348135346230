import React,{Component} from 'react';
import './Menu.css';
import Header2 from '../Header2';
import {Link} from 'react-router-dom';


const UpdatesUrl = "https://deglennow.onrender.com/rooms?details="
const updateputurl = "https://deglennow.onrender.com/roomsupdate"


class RoomUpdate extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside ROMCOnstructor")
        this.state={
            roomtype_Name:'',
            room_images:'',
            room_rate:'',
            Description:'',
            Extras1:'',
            Extras2:'',
            Extras3:'',
            Extras4:'',
            Extras5:'',
            Extras6:'',
            Extras7:'',
            Extras8:'',
            facilty1:'',
            facility_Image2:'',
            facilty_description3:'',
            facilty2:'',
            facility_Image3:'',
            facilty_description4:'',
            facilty3:'',
            facility_Image4:'',
            facilty_description5:'',
            facilty4:'',
            facility_Image5:'',
            facilty_description6:'',
            facilty5:'',
            facility_Image6:'',
            facilty_description7:'',
            gallery1:'',
            gallery2:'',
            gallery3:'',
            gallery4:'',
            gallery5:'',
            gallery6:''
            
           
            
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    

    async handleSubmit() {
        try {
            var pid = this.props.match.params.ruID;
            let result = await fetch(`${updateputurl}/${pid}`, {
                method: 'put',

                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    this.state
                )
                
            });

            console.log('result>  ' + result)
            alert("Data Updated Successfully")
            fetch(`${UpdatesUrl}${pid}`, {method:'GET'})
            .then((res) => res.json())
            .then((data) => {
            data.map((item)=>{
                this.setState({roomtype_Name:item.roomtype_Name})
                this.setState({room_images:item.room_images})
                this.setState({room_rate:item.room_rate})
                this.setState({Description:item.Description})
                this.setState({Extras1:item.Extras1})
                this.setState({Extras2:item.Extras2})
                this.setState({Extras3:item.Extras3})
                this.setState({Extras4:item.Extras4})
                this.setState({Extras5:item.Extras5})
                this.setState({Extras6:item.Extras6})
                this.setState({Extras7:item.Extras7})
                this.setState({Extras8:item.Extras8})
                this.setState({facilty1:item.facility1})
                this.setState({facility_Image2:item.facility_Image2})
                this.setState({facilty_description3:item.facilty_description3})
                this.setState({facilty2:item.facility2})
                this.setState({facility_Image3:item.facility_Image3})
                this.setState({facilty_description4:item.facilty_description4})
                this.setState({facilty3:item.facilty3})
                this.setState({facility_Image4:item.facility_Image4})
                this.setState({facilty_description5:item.facilty_description5})
                this.setState({facilty4:item.facilty4})
                this.setState({facility_Image5:item.facility_Image5})
                this.setState({facilty_description6:item.facilty_description6})
                this.setState({facilty5:item.facilty5})
                this.setState({facility_Image6:item.facility_Image6})
                this.setState({facilty_description7:item.facilty_description7})
                this.setState({gallery1:item.gallery1})
                this.setState({gallery2:item.gallery2})
                this.setState({gallery3:item.gallery3})
                this.setState({gallery4:item.gallery4})
                this.setState({gallery5:item.gallery5})
                this.setState({gallery6:item.gallery6})
                return 'ok'
            })
            alert("Data Reloaded")

        })
        
        } catch(e) {
            console.log(e)
        }
    }

    render(){
        console.log (">>> Inside ROMdetails", this.state)
        if(localStorage.getItem('rtk') == null){
            return(
                <>
                    <Header2/>
                    <div className="background10">
                        <div>
                            <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                        </div>
                
                    </div>
                </>
                
            )
        }
        return(
            <>
                <Header2/>
                <div className="container background11">
                    <center>
                        <div className="formdesign11">
                            <h5>Settings for {this.state.roomtype_Name}</h5>
                           
                            <form><br/>
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h5 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"  aria-expanded="false" aria-controls="flush-collapseOne">
                                                <b>Room Settings</b>
                                            </button>
                                        </h5>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <label className='form-label'>Room Name</label>
                                                <input type="text" className="form-control mb-3" name="roomtype_Name" value={this.state.roomtype_Name} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Image</label>
                                                <input type="text" className="form-control mb-3" name="room_images" value={this.state.room_images} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Rate</label>
                                                <input type="number"className="form-control mb-3" name="room_rate" value={this.state.room_rate} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Description</label>
                                                <textarea row="4" className="form-control mb-3" name="Description" value={this.state.Description} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                <b>Room Gallery Settings</b>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <label className='form-label'>Room Image 1</label>
                                                <input type="text" className="form-control mb-3" name="gallery1" value={this.state.gallery1} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Image 2</label>
                                                <input type="text" className="form-control mb-3" name="gallery2" value={this.state.gallery2} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Image 3</label>
                                                <input type="text" className="form-control mb-3" name="gallery3" value={this.state.gallery3} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Image 4</label>
                                                <input type="text" className="form-control mb-3" name="gallery4" value={this.state.gallery4} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Image 5</label>
                                                <input type="text" className="form-control mb-3" name="gallery5" value={this.state.gallery5} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Image 6</label>
                                                <input type="text" className="form-control mb-3" name="gallery6" value={this.state.gallery6} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                <b>Room Extras Settings</b>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <label className='form-label'>Room Extras 1</label>
                                                <input type="text" className="form-control mb-3" name="Extras1" value={this.state.Extras1} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Extras 2</label>
                                                <input type="text" className="form-control mb-3" name="Extras2" value={this.state.Extras2} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Extras 3</label>
                                                <input type="text" className="form-control mb-3" name="Extras3" value={this.state.Extras3} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Extras 4</label>
                                                <input type="text" className="form-control mb-3" name="Extras4" value={this.state.Extras4} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Extras 5</label>
                                                <input type="text" className="form-control mb-3" name="Extras5" value={this.state.Extras5} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Extras 6</label>
                                                <input type="text" className="form-control mb-3" name="Extras6" value={this.state.Extras6} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Extras 7</label>
                                                <input type="text" className="form-control mb-3" name="Extras7" value={this.state.Extras7} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Extras 8</label>
                                                <input type="text" className="form-control mb-3" name="Extras8" value={this.state.Extras8} onChange={this.handleChange}/>
                                            
                                            </div>
                                        </div>

                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                <b>Room Facilities Settings</b>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <label className='form-label'>Room Facility 1</label>
                                                <input type="text" className="form-control mb-3" name="facilty1" value={this.state.facilty1} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 1 Image</label>
                                                <input type="text" className="form-control mb-3" name="facility_Image2" value={this.state.facility_Image2} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 1 Description</label>
                                                <input type="text" className="form-control mb-3" name="facilty_description3" value={this.state.facilty_description3} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 2</label>
                                                <input type="text" className="form-control mb-3" name="facilty2" value={this.state.facilty2} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 2 Image</label>
                                                <input type="text" className="form-control mb-3" name="facility_Image3" value={this.state.facility_Image3} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 2 Description</label>
                                                <input type="text" className="form-control mb-3" name="facilty_description4" value={this.state.facilty_description4} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 3</label>
                                                <input type="text" className="form-control mb-3" name="facilty3" value={this.state.facilty3} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 3 Image</label>
                                                <input type="text" className="form-control mb-3" name="facility_Image4" value={this.state.facility_Image4} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 3 Description</label>
                                                <input type="text" className="form-control mb-3" name="facilty_description5" value={this.state.facilty_description5} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 4</label>
                                                <input type="text" className="form-control mb-3" name="facilty4" value={this.state.facilty4} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 4 Image</label>
                                                <input type="text" className="form-control mb-3" name="facility_Image5" value={this.state.facility_Image5} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 4 Description</label>
                                                <input type="text" className="form-control mb-3" name="facilty_description6" value={this.state.facilty_description6} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 5</label>
                                                <input type="text" className="form-control mb-3" name="facilty5" value={this.state.facilty5} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 5 Image</label>
                                                <input type="text" className="form-control mb-3" name="facility_Image6" value={this.state.facility_Image6} onChange={this.handleChange}/>
                                                <label className='form-label'>Room Facility 5 Description</label>
                                                <input type="text" className="form-control mb-3" name="facilty_description7" value={this.state.facilty_description7} onChange={this.handleChange}/>
                                            
                                            </div>
                                        </div>

                                    </div>

                                </div>
                             
                            </form>
                            <div>
                                <span><button className="btn btn-primary" onClick={ () => this.handleSubmit () }>Save Changes</button>
                                    <Link to="/update">
                                        <button className="btn btn-primary space">Close</button>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </center>
               
                
                </div>                
            </>
            
        )

    }
    componentDidMount(){
        var romID = this.props.match.params.ruID;
        fetch(`${UpdatesUrl}${romID}`, {method:'GET'})
        .then((res) => res.json())
        .then((data) => {
            data.map((item)=>{
                this.setState({roomtype_Name:item.roomtype_Name})
                this.setState({room_images:item.room_images})
                this.setState({room_rate:item.room_rate})
                this.setState({Description:item.Description})
                this.setState({Extras1:item.Extras1})
                this.setState({Extras2:item.Extras2})
                this.setState({Extras3:item.Extras3})
                this.setState({Extras4:item.Extras4})
                this.setState({Extras5:item.Extras5})
                this.setState({Extras6:item.Extras6})
                this.setState({Extras7:item.Extras7})
                this.setState({Extras8:item.Extras8})
                this.setState({facilty1:item.facilty1})
                this.setState({facility_Image2:item.facility_Image2})
                this.setState({facilty_description3:item.facilty_description3})
                this.setState({facilty2:item.facilty2})
                this.setState({facility_Image3:item.facility_Image3})
                this.setState({facilty_description4:item.facilty_description4})
                this.setState({facilty3:item.facilty3})
                this.setState({facility_Image4:item.facility_Image4})
                this.setState({facilty_description5:item.facilty_description5})
                this.setState({facilty4:item.facilty4})
                this.setState({facility_Image5:item.facility_Image5})
                this.setState({facilty_description6:item.facilty_description6})
                this.setState({facilty5:item.facilty5})
                this.setState({facility_Image6:item.facility_Image6})
                this.setState({facilty_description7:item.facilty_description7})
                this.setState({gallery1:item.gallery1})
                this.setState({gallery2:item.gallery2})
                this.setState({gallery3:item.gallery3})
                this.setState({gallery4:item.gallery4})
                this.setState({gallery5:item.gallery5})
                this.setState({gallery6:item.gallery6})
                return 'ok'
            })
        })

        
        
    }


   
}

export default RoomUpdate;

