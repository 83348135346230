import React, { Component } from 'react';
import './Menu.css';
import Header2 from '../Header2';
import {Link} from 'react-router-dom';


const youtube = "https://deglennow.onrender.com/youtube?video=";
const postyoutube = "https://deglennow.onrender.com/postyoutube";
const delyoutube = "https://deglennow.onrender.com/delvid";
const updateyoutube = "https://deglennow.onrender.com/putVid";
const delvideo1 = "https://deglennow.onrender.com/delactivevideo";
const delvideo2 = "https://deglennow.onrender.com/postnewvideo";



class video extends Component {
    constructor(props) {
        super(props);
        console.log (">>>Inside ROMCOnstructor")
        this.state={
            _id:Math.floor(Math.random()*10000),
            video:'',
            videofiles:'',
            activeVideo:''
                
        }

        
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    openForedit(){
        fetch(`${youtube}${sessionStorage.getItem('getvidID')}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
           data.map((item)=>{
            this.setState({video:item.video})
            return 'ok'
           })
          
            
        })
    }
    cancel(){
        this.setState({video:''})
    }

    cancel2(){
        this.setState({activeVideo:''})
    }

    deleteVid(){
        fetch(`${delyoutube}/${sessionStorage.getItem('getvideoID')}`, {method:'delete'})

        this.myTimer = setTimeout(() => {
            fetch(`${youtube}`, {method:'GET'})
            .then((res) => res.json())
            .then((data) => {
                this.setState({videofiles:data})
            })
            
        },2000);
        alert('Video deleted successfully')
       
    }


    async handleSubmit() {
        try {
            var sendVideo = {
                _id:`${this.state._id}`,
                video:`${this.state.video}`
                
            };

            let result = await fetch(`${postyoutube}`, {
                method: 'post',

                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    sendVideo
                )
                
            });

            console.log('result>  ' + result)
            this.setState({video:''})
            alert("Video Added Successfully")
            fetch(`${youtube}`, {method:'GET'})
            .then((res) => res.json())
            .then((data) => {
            this.setState({videofiles:data})
            
        })
        
        } catch(e) {
            console.log(e)
        }
    }

    async handleSubmit2() {
        try {
            var updateVideo = {
                video:`${this.state.video}`,
            }

            var id = parseInt(sessionStorage.getItem('getvidID'));

            let result = await fetch(`${updateyoutube}/${id}`, {
                method: 'put',

                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    updateVideo
                )
                
            });

            console.log('result>  ' + result)
            this.setState({video:''})
            alert("Video updated Successfully")
            fetch(`${youtube}`, {method:'GET'})
            .then((res) => res.json())
            .then((data) => {
            this.setState({videofiles:data})
            
        })
        
        } catch(e) {
            console.log(e)
        }
    }

    async handleSubmit3() {
        try {
            var sendVideo = {
                video:`${this.state.activeVideo}`,
                _id:`${this.state._id}`,
            }

            let result = await fetch(`${delvideo1}`, {
                method: 'delete',

                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    sendVideo
                )
                
            });

            console.log('result>  ' + result)

            let result2 = await fetch(`${delvideo2}`, {
                method: 'post',

                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    sendVideo
                )
                
            });

            console.log('result2>  ' + result2)
            this.myTimer = setTimeout(() => {
               this.setState({activeVideo:'',videofiles:''});
               fetch(`${youtube}`, {method:'GET'})
                .then((res) => res.json())
                .then((data) => {
                    this.setState({videofiles:data})
                })

        
            },1000);
            alert("Video Activated Successfully")
            
           
        
        } catch(e) {
            console.log(e)
        }
    }

    renderVideofiles=(data)=>{
        if(data){
            
            data.sort((a, b) => a.video - b.video);
            return data.map((item)=>{
                return(
                    <>
                        <tr key= {item._id}>
                                
                            <td className="table-light table-striped">{item.video}</td>
                            <td className="table-light table-striped adjust10"><button className="btn btn-primary textcolor" onMouseOver={()=>sessionStorage.setItem('getvidID', item._id)} onClick={()=>{this.openForedit()}}>Edit</button></td>
                            <td className="table-light table-striped adjust10"><button className="btn btn-danger textcolor" onMouseOver={()=>sessionStorage.setItem('getvideoID', item._id)} onClick={()=>{this.deleteVid()}}>Delete</button></td>    
                        </tr>
                    </>
                )
                
            })
            
        }
           
    }

    renderforActiveVideo(data){
        if(data) {
           data.sort((a, b) => a.video - b.video);
            return data.map((item) => {
                
                return(
                    <>
                        <option key={item._id} value={item.video}>
                            {item.video}
                        </option>
                    </>
                )
                
            })
        }
    }


    render(){
        console.log (">>>Inside render",this.state)
        if(localStorage.getItem('rtk') == null){
            return(
                <>
                    <Header2/>
                    <div className="background10">
                        <div>
                            <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                        </div>
                
                    </div>
                </>
                
            )
        }
        return(
            <>
                <Header2/>
                <div className="container background11">
                    <div className="formdesign11">
                        <h6>Activate Video</h6>
                        <br/>
                        <select className="form-select  mb-3" name="activeVideo" onChange={this.handleChange}>
                            <option defaultValue=''>Select a video and make it active on the home page</option>
                            {this.renderforActiveVideo(this.state.videofiles)}
                        </select>
                    </div>
                    <center>
                        <button className="btn btn-primary" onClick={()=>this.handleSubmit3()}>Activate</button>
                        <button className="btn btn-danger space" onClick={()=>this.cancel2()}>Cancel</button>
                        
                    </center>
                    <br/>
                    
                    <hr/>
                    <div className="formdesign11">
                        <h6>Edit and Add Video Links</h6>
                        <br/>
                        <input type="text" className="form-control mb-3" name="video" require placeholder="Add new video links or click edit below to edit existing videos Here" value={this.state.video} onChange={this.handleChange}/>
                    </div>
                    <center>
                        <button className="btn btn-primary" onClick={()=>this.handleSubmit()}>Add New</button>
                        <button className="btn btn-primary space" onClick={()=>this.handleSubmit2()}>Update Video</button>
                        <button className="btn btn-danger space" onClick={()=>this.cancel()}>Cancel</button>
                       
                    </center>
                    <br/>
                    <div>
                        <table className="table table-hover">
                            
                            <thead className="table-warning">
                                <tr>
                                    <th className="adjust5">Video Links</th>
                                    <th className="adjust5">Action</th>
                                    <th className="adjust5"> </th>
                                            
                                </tr>
                            </thead>
                            <tbody className="table table-hover">
                                {this.renderVideofiles(this.state.videofiles)}
                            
                            </tbody>
                        </table>
                        <br/>
                        <center>
                            <button className="btn btn-danger" onClick={()=>this.props.history.push('/Settings')}>Close</button>
                        </center>
                   
                    </div>
                    
                </div>
                
            </>
        )

        
    }
    componentDidMount(){
        fetch(`${youtube}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
            this.setState({
                videofiles:data
            })
            
        })
    }
    
}

export default video;