import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas';
import CloseButton from 'react-bootstrap/CloseButton';
import {withRouter} from 'react-router-dom';
import './Header.css';


class Header extends Component{
    constructor(props){
        super(props);
        console.log(">>> inside nanetConstructor", props)
        this.state={
            show:false
        }
    }

    handleShow(){
        this.setState({show:true})
    }

    handleClose(){
        this.setState({show:false})
    }


    render(){

        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light bg-dark widthresize">
                    <div className="container-fluid ">
                        <div className="navbar-brand">
                            <Link to="/">
                                <center><img className="logoresize" src= "https://i.ibb.co/sHNG07v/logo-5c.png" alt="logo"/></center>
                            </Link>
                        </div>
                        <span className='alignmenu'>
                            
                            <button className="navbar-toggler" type = "button" data-bs-toggle = "collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon buttonformat"></span>
                            </button>
                        </span>
                        <span className='alignmenu2 hide'>
                            <div className='hide alignmenu2'>
                                <i type="button" className="fa fa-bars size2 alignmenu2 hide" onClick={()=>this.handleShow()}></i>
                            </div>
                            
                    
                        </span>
                        <div className="collapse navbar-collapse alignmenu shiftmenu" id="navbarText">
                            <center>
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item "></li>
                                    <li className="nav-link active" aria-current="page"> <Link to="/" className="navtext">Home </Link></li>
                                    <li className="nav-item"></li>
                                    <li className="nav-link "> <Link to="/booking" className="navtext">Rooms</Link></li>
                                    <li className="nav-item "></li>
                                    <li className="nav-link "> <Link to="/explore" className="navtext">Explore</Link></li>
                                    <li className="nav-link "> <Link to="/Events" className="navtext">Events</Link></li>
                                    <li className="nav-link "> <Link to="/DeGlenBlog" className="navtext">Blog</Link></li>
                                    <li className="nav-item "></li>
                                    <li className="nav-link "> <Link to="/Contact" className="navtext">Contact Us</Link></li> &nbsp;
                                    <Link to="/Settings">
                                        <i className="fa fa-cog fa-spin fa-1.8x fa-fw envelopefrmt"></i>
                                        <span className="sr-only">Loading...</span>
                                    </Link>
                                    <div>
                                        <a className="shift" href="https://emailmg.globat.com/roundcube/" target='_blank' rel="noopener noreferrer" aria-label='email'>
                                            <i className="fa fa-envelope-o envelopefrmt" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </ul>
                                <span>
                            
                            </span>
                            </center>    

                        </div>
                        
                    </div>
                </nav>

                <Offcanvas show={this.state.show}>
                    
                    <div className="headerbackgrd3 alignmenu2 hide">
                        <Offcanvas.Body>
                            <div>
                                <span>
                                    <a className="shift2" href="https://emailmg.globat.com/roundcube/" target='_blank' rel="noopener noreferrer" aria-label='email'>
                                        <i className="fa fa-envelope-o envelopefrmt size4" aria-hidden="true"></i>
                                    </a>
                                    <span className="size4b envelopefrmt">email</span>
                                    <Link to="/Settings">
                                        <a className="shift2" href="https://emailmg.globat.com/roundcube/" target='_blank' rel="noopener noreferrer" aria-label='email'>
                                            <i className="fa fa-cogs envelopefrmt size4cd" aria-hidden="true"></i>
                                        </a>
                                        <span className="size4ba envelopefrmt">Settings</span>
                                    </Link>
                                    
                                </span>

                            </div>
                            <div className="bg-dark p-3 size3">
                                <CloseButton variant="white" onClick={()=>this.handleClose()}/>
                                
                            </div>
                            
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/booking');this.handleClose()}}>Rooms</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/explore');this.handleClose()}}>Explore</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/Events ');this.handleClose()}}>Events</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/DeGlenBlog ');this.handleClose()}}>Blog</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/Contact');this.handleClose()}}>Contact Us</p>
                                </center>
                            </div>
                            
                            
                        </Offcanvas.Body>
                    </div>
                    
                </Offcanvas>
            
            </>
        )
    }
}


export default withRouter (Header);