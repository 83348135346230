import React,{Component} from 'react';
import './Details.css';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import addDays from 'date-fns/addDays';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format'; 
import Header from '../Header';
import Carousel from 'react-bootstrap/Carousel';



const detailsUrl = "https://deglennow.onrender.com/rooms?details="


class RoomDetails extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside RMCOnstructor")

        this.state={
            RMdetails:'',
            startDate:'',
            endDate:'',
            persons:'',
            kids:''
            
        };
        this.checkinhandleChange = this.checkinhandleChange.bind(this);
        this.checkouthandleChange = this.checkouthandleChange.bind(this);
        
    }
    checkinhandleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }


    checkouthandleChange(date) {
        this.setState({
            endDate: date
        });
    }
       
    rendercheckinDate() {
        return (
            <div>
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.checkinhandleChange}
                    minDate={new Date()}
                    maxDate={addDays(new Date(),26)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    placeholderText="Check-In Date"
                    className="form-control mb-4 formsize51"
                    
                />
            </div>
        )
    }
    
    rendercheckOutDate() {
        return (
            <div >
                <DatePicker
                    selected={this.state.endDate}
                    onChange={this.checkouthandleChange}
                    minDate={addDays(this.state.startDate,1)}
                    maxDate={addDays(new Date(),30)}
                    dropdownMode="select"
                    dateFormat="MMM/d/ yyyy"
                    placeholderText="Check-Out Date"
                    className="form-control mb-4 formsize51"
                />
            </div>
        )
    }

    proceed = () => {
        var rmdetails = this.state.RMdetails;
        rmdetails.map((item) => {
            sessionStorage.setItem('checkin',this.state.startDate);
            sessionStorage.setItem('checkout',this.state.endDate);
            sessionStorage.setItem('images', `${item.room_images}`)
            sessionStorage.setItem('rate', `${item.room_rate}`)
            this.props.history.push(`/checkIn/${item.roomtype_Name}`)
            
            return 'ok'
        })
        
            
    }

    
   
    renderDetails = (data) => {
        if (data){
            return data.map((item) => {
                return(
                    <>
                        
                        <div className='use1'>
                            <div className='use2'>
                                <span className="Label">
                                    <center>
                                        <div className="bordered">
                                            <span>From</span>&nbsp;
                                            <span style={{fontSize:"25px"}}><NumberFormat value={item.room_rate} thousandSeparator={true} displayType={"text"} prefix={"NGN"}/>.00</span> &nbsp;
                                            <span>Per Night</span>
                                        </div>
                                    </center>
                                    <hr style={{color:"gold"}}/><br/>

                                    <div className="container">
                                        <label>
                                            {this.rendercheckinDate(this.state.startDate)}
                                        </label>

                                        <label>
                                            {this.rendercheckOutDate(this.state.endDate)}
                                        </label>

                                        <input type="text" className="form-control mb-4 formsize51" name="persons" require placeholder="# of Persons" value={this.state.persons} list="persons" onChange={this.handleChange}/>
                                        <datalist id="persons">
                                            <option value = "1 Adult"></option>
                                            <option value = "2 Adults"></option>
                                        </datalist>

                                        <input type="text" className="form-control mb-4 formsize51" name="kids" require placeholder="# of Kids" value={this.state.kids} list="kids" onChange={this.handleChange}/>
                                        <datalist id="kids">
                                            <option value = "1 Kid"></option>
                                            <option value = "2 Kids"></option>
                                        </datalist>

                                    </div><hr style={{color:"gold"}}/><br/>
                                    
                                    <center>
                                        <button className="btn butt" disabled={this.state.startDate===''||this.state.endDate===''} style={{color:"gold", fontFamily:"Georgia, 'Times New Roman', Times, serif"}}  onClick={this.proceed}>
                                            Book Now
                                        </button>
                                    </center>
                                    
                                </span>
                            </div>

                            <div className='use3'>
                                <span className="Label2">
                                    <div className='frameholder'>
                                        
                                        <Carousel>
                                            <Carousel.Item className="carouselBx">
                                                <img src={item.gallery1} className="d-block w-100 banner" alt="FirstSlide"/>
                                                <Carousel.Caption>
                                                
                                                <div className="carouselBx2">
                                                    <p className="carouselBx3 mb-3">{item.roomtype_Name}</p>
                                                    <p className="carouselBx4"> </p><br/>
                                                    
                                                </div>
                                                
                                                </Carousel.Caption>
                                            </Carousel.Item>

                                            <Carousel.Item className="carouselBx">
                                                <img src={item.gallery2} className="d-block w-100 banner" alt="FirstSlide"/>
                                                <Carousel.Caption>
                                                
                                                <div className="carouselBx2">
                                                    <p className="carouselBx3 mb-3">{item.roomtype_Name}</p>
                                                    <p className="carouselBx4"> </p><br/>
                                                    
                                                </div>
                                                
                                                </Carousel.Caption>
                                            </Carousel.Item>

                                            <Carousel.Item className="carouselBx">
                                                <img src={item.gallery3} className="d-block w-100 banner" alt="FirstSlide"/>
                                                <Carousel.Caption>
                                                
                                                <div className="carouselBx2">
                                                    <p className="carouselBx3 mb-3">{item.roomtype_Name}</p>
                                                    <p className="carouselBx4"> </p><br/>
                                                    
                                                </div>
                                                
                                                </Carousel.Caption>
                                            </Carousel.Item>

                                            <Carousel.Item className="carouselBx">
                                                <img src={item.gallery4} className="d-block w-100 banner" alt="FirstSlide"/>
                                                <Carousel.Caption>
                                                
                                                <div className="carouselBx2">
                                                    <p className="carouselBx3 mb-3">{item.roomtype_Name}</p>
                                                    <p className="carouselBx4"> </p><br/>
                                                    
                                                </div>
                                                
                                                </Carousel.Caption>
                                            </Carousel.Item>

                                            <Carousel.Item className="carouselBx">
                                                <img src={item.gallery5} className="d-block w-100 banner" alt="FirstSlide"/>
                                                <Carousel.Caption>
                                                
                                                <div className="carouselBx2">
                                                    <p className="carouselBx3 mb-3">{item.roomtype_Name}</p>
                                                    <p className="carouselBx4"> </p><br/>
                                                    
                                                </div>
                                                
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        </Carousel>

                                    </div>
                                    
                                </span>
                            </div>
                            
                            <div className='use4'>
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h5 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"  aria-expanded="false" aria-controls="flush-collapseOne">
                                                <b>Room Details</b>
                                            </button>
                                        </h5>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <span>
                                                    <div className="card col-md-3 rcard">
                                                        <img src={item.facility_Image2} className="card-img-top icard" alt={item.facilty1}/>
                                                        <center><h5 style={{marginTop:"-24px"}} >{item.facilty1}</h5></center>
                                                            
                                                    </div>
                                                </span>
                                                <span>
                                                    <div className="card col-md-3 rcard">
                                                        <img src={item.facility_Image3} className="card-img-top jcard" alt={item.facilty2}/>
                                                        <center><h5 style={{marginTop:"-19px"}} >{item.facilty2}</h5></center>
                                                            
                                                    </div>
                                                </span>
                                                <span>
                                                    <div className="card col-md-3 rcard">
                                                        <img src={item.facility_Image4} className="card-img-top kcard" alt={item.facilty3}/>
                                                        <center><h5 style={{marginTop:"-6px"}} >{item.facilty3}</h5></center>
                                                            
                                                    </div>
                                                </span>
                                                <span>
                                                    <div className="card col-md-3 rcard">
                                                        <img src={item.facility_Image5} className="card-img-top lcard" alt={item.facilty4}/>
                                                        <center><h5 style={{marginTop:"-10px"}} >{item.facilty4}</h5></center>
                                                            
                                                    </div>
                                                </span>
                                                <span>
                                                    <div className="card col-md-3 rcard">
                                                        <img src={item.facility_Image6} className="card-img-top mcard" alt={item.facilty5}/>
                                                        <center><h5 style={{marginTop:"-25px"}} >{item.facilty5}</h5></center>
                                                            
                                                    </div>
                                                </span>
                                                                                                                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                <b>Description</b>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <p>{item.Description}</p>
                                                
                                            </div>
                                        </div>

                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                <b>Extras</b>
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">
                                                <ul class="list-group">
                                                    <li class="list-group-item"><i className="fa fa-bed text-primary"></i>&nbsp;&nbsp;{item.Extras1}</li>
                                                    <li class="list-group-item"><i className="fa fa-bath text-primary"></i>&nbsp;&nbsp;{item.Extras2}</li>
                                                    <li class="list-group-item"><i className="fa fa-thermometer-half text-primary"></i>&nbsp;&nbsp;{item.Extras3}</li>
                                                    <li class="list-group-item"><i className="fa fa-glass text-primary"></i>&nbsp;&nbsp;{item.Extras4}</li>
                                                    <li class="list-group-item"><i className="fa fa-shield text-primary"></i>&nbsp;&nbsp;{item.Extras5}</li>
                                                    <li class="list-group-item"><i className="fa fa-video-camera text-primary"></i>&nbsp;&nbsp;{item.Extras6}</li>
                                                    <li class="list-group-item"><i className="fa fa-lock text-primary"></i>&nbsp;&nbsp;{item.Extras7}</li>
                                                    <li class="list-group-item"><i className="fa fa-car text-primary"></i>&nbsp;&nbsp;{item.Extras8}</li>
                                                </ul>
                                                
                                            </div>
                                        </div>

                                    

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        

                           
                        
                    </>
                            
                )   

            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }   
    }

    render(){
        console.log (">>> Inside RMdetails", this.state)
        return(
            <>
                <Header/>
                
                {this.renderDetails(this.state.RMdetails)}
                    
            
            </>
            
        )
    }

    async componentDidMount(){
        console.log (">>> Inside RMdidMount")

        const rmID = this.props.match.params.id;
        const response = await axios.get(`${detailsUrl}${rmID}`)
        this.setState({
            RMdetails:response.data,
           
        })
        
        if(localStorage.getItem('arrivalAvailability')===null||localStorage.getItem('endAvailability')===null||localStorage.getItem('kidsAvailability')===null||localStorage.getItem('personsAvailability')===null){
            this.setState({
                startDate:'',
                endDate:'',
                kids:'',
                persons:''
            })
        }

        else{
            this.setState({
                startDate:new Date(localStorage.getItem('arrivalAvailability')),
                endDate:new Date(localStorage.getItem('endAvailability')),
                kids:localStorage.getItem('kidsAvailability'),
                persons:localStorage.getItem('personsAvailability')

            })
        }
       
    }
}

export default RoomDetails;

