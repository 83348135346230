import React,{Component} from 'react';
import Header from '../Header';
import '../Booking/Reservation.css';





const GetBlogurl = "https://deglennow.onrender.com/Getblog";

class DeGlenBlog extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside ROMCOnstructor")
        this.state={
            id:Math.floor(Math.random()*100000),
            blogFiles:''

            
        }
    }

    onOpenModaledit(){
        this.props.history.push(`/BlogStory/${sessionStorage.getItem('getThisBlog')}`)
    }


   
    renderBlogs=(data)=>{
        if(data){
            
            data.sort((a, b) => new Date(b.date) - new Date(a.date));
            return data.map((item)=>{
                
                return(
                    <>
                        
                        <div className='blogCard'>
                            
                            
                            <div className="card imageSizing">
                                <img src={item.image} className="card-img-top Resize" alt="images"/>

                                <div className="itemPlacement colorSetting">
                                    <h6 className='setTxt'>{item.title}</h6>
                                </div>
                                <div className='itemPlacement2'>
                                    <button className='btn btn-warning fontSetting' onMouseOver={()=>sessionStorage.setItem('getThisBlog', item._id)} onClick={()=>this.onOpenModaledit()}>Read More</button>
                                </div>
                            </div>
                        </div>
                       
                    </>
                )
                
            })
            
        }
           
    }

    render(){
        console.log (">>> Inside ROMdetails", this.state)
        
        return(
            <div>
                
                <Header/>
                <div className="container">
                    <div>
                        <h3 style={{textAlign:"center"}}>De Glen Hotel Blog</h3>
                        {this.renderBlogs(this.state.blogFiles)}
                    </div>
                </div>
               
            </div>
            
        )

    }
    componentDidMount(){
        fetch(`${GetBlogurl}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
            this.setState({
                blogFiles:data
            })

        })

    }

}

export default DeGlenBlog;
   
   
   
    
   
   
  