import React from 'react';
import HomeCarousel from './HomeCarousel';
import RoomDisplay from './RoomDisplay';






const Home = (props) => {
    
    
    return(
        <>
            
            <HomeCarousel/>
            <RoomDisplay/>
        </>
    )
}

export default Home;