import React,{Component} from 'react';
import Header2 from '../Header2';
import { Link } from 'react-router-dom';
import './Menu.css';






const updtroomUrl = "https://deglennow.onrender.com/rooms"


class UpdateRooms extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside UPCOnstructor")

        this.state={
            roomtype:''
            
            
        }

    }
    renderRoomtypes=(data) =>{
        if (data){
            return data.map((item) =>{
                return(
                    <>
                        <div>
                            <Link to={`/rmupdate/${item.roomtype_id}`} key={item.roomtype_id}>
                                <button type="button" className="btn btn-outline-primary btnwidth10">{item.roomtype_Name}</button>
                            </Link>
                        </div>
                    </>
                    
                )
            })
        }
    }
    render(){
        if(localStorage.getItem('rtk') == null){
            return(
                <>
                    <Header2/>
                    <div className="background10">
                        <div>
                            <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                            
                            
                        </div>
                    
                    </div>
                </>
                
            )
        }
        return(
            <>
                <Header2/>
                <div className="background10">
                    <div className="formdesign10">
                        <center><h5 className="editselect">Select Room Type</h5></center>
                        {this.renderRoomtypes(this.state.roomtype)} <br/>

                        <Link to="/Settings">
                            <center><button className="btn btn-primary space mb-3">Close</button></center>
                        </Link>
                       
                    </div>
                    
                    
                </div>
            </>
        )
    }
    componentDidMount(){
        
        fetch(updtroomUrl, {method:'GET'})
        .then((res) => res.json())
        .then((data) => {
            this.setState({roomtype:data})
        })

    }


}

export default UpdateRooms;

