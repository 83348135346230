import React from "react";
import './Menu.css';
import Header2 from '../Header2';
import { Link } from "react-router-dom";


const Menu = (props) => {
    if(localStorage.getItem('rtk') == null){
        return(
            <>
                <Header2/>
                <div className="background10">
                    <div>
                        <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                        
                    </div>
                
                </div>
            </>
            
        )
    }
    return(
        <>
        <Header2/>
        <div className="background10">
            <center>
                        <div className="formdesign10">
                            <h5 className = "mb-3">Menu</h5>
                            <Link to="/reservations">
                                <center>
                                    <button className="btn btn-primary btnwidth10 mb-3">Reservations</button>
                                </center>
                            </Link>
                            <Link to="/update">
                                <center>
                                    <button className="btn btn-primary btnwidth10 mb-3">Room Settings</button>
                                </center>
                            </Link>
                            <Link to="/ContactNow">
                                <center>
                                    <button className="btn btn-primary btnwidth10 mb-3">Contact Messages</button>
                                </center>
                            </Link>
                            <Link to="/videoupdate">
                                <center>
                                    <button className="btn btn-primary btnwidth10 mb-3">Home Page Video Update</button>
                                </center>
                            </Link>

                            <Link to="/Blogger">
                                <center>
                                    <button className="btn btn-primary btnwidth10 mb-3">Post Blog</button>
                                </center>
                            </Link>
                        </div>
                    </center>

        </div>
        </>
    )
}
export default Menu;